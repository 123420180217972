import type {
  Recommendation,
  RecommendationChannelEnum,
} from "~/src/generated-sources/public";
import { errorHandlerFactory } from "~/utils/error-mapper";

type CreateRecommendationFields = Omit<Recommendation, "channel" | "code">;

export default async function (
  fields: CreateRecommendationFields,
  channel: RecommendationChannelEnum,
  snackbar: any,
) {
  const { $brain } = useNuxtApp();

  const recommendation = ref<Recommendation>();

  await $brain.public.recommendations
    .createRecommendation({
      emailAddress: fields.emailAddress,
      firstName: fields.firstName,
      lastName: fields.lastName,
      channel,
      // TODO: Falsely required by backend
      code: "any",
    })
    .then((res) => {
      recommendation.value = res.data;

      useEmitFormSubmit("recommendationForm");
    })
    .catch((error) => {
      const errorMessage = errorHandlerFactory(error);

      snackbar.add({
        type: "error",
        text: Object.values(errorMessage)[0],
      });
    });

  return recommendation;
}
